import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql } from 'gatsby';
import moment from 'moment';
import { InformationCircleIcon } from '@heroicons/react/outline';

import BannerImage from '../components/therapy/BannerImage';
import CommonLayout from '../components/layout/CommonLayout';
import TherapyCalculatorForm from '../components/therapy/TherapyCalculatorForm';

import PageMeta from '../components/PageMeta';

import 'moment/locale/ro';
import { useModal } from '../components/layout/Modal';
import TherapyInstructionsModal from '../components/therapy/TherapyInstructionsModal';

const IndexPage = () => {
  const { t: translate, i18n } = useTranslation();
  const [isModalOpen, toggleModal] = useModal();

  useEffect(() => {
    moment.locale('ro');
    i18n.changeLanguage('ro');
  }, [i18n]);

  return (
    <main>
      <PageMeta />
      <div className={'flex flex-col items-center'}>
        <CommonLayout>
          <h1
            className={
              'my-3 text-center text-primary-blue-800 text-3xl font-medium leading-8 px-4'
            }
          >
            {translate('title')}
          </h1>
          <p className={'opacity-90 text-primary-blue-800 text-center px-3'}>
            {translate('description')}
            <InformationCircleIcon
              className={
                'inline-flex h-4 w-4 mb-1 mx-1 text-base cursor-pointer rounded-full hover:opacity-80'
              }
              onClick={toggleModal}
            />
          </p>
          <TherapyInstructionsModal
            toggleModal={toggleModal}
            isOpen={isModalOpen}
          />

          <TherapyCalculatorForm />
          <div className={'mt-6 px-5 pt-2 md:px-0'}>
            <BannerImage />
          </div>

          <div
            className={'flex justify-center items-center space-x-1 py-2 mt-24'}
          >
            <span className={'text-xs text-gray-500'}>Powered by</span>
            <button
              className={'text-blue-600 text-xs hover:text-blue-700'}
              onClick={() => {
                window.open(
                  'https://www.iryo.io',
                  '_blank',
                  'noopener,noreferrer'
                );
              }}
            >
              Iryo
            </button>
          </div>
        </CommonLayout>
      </div>
    </main>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
