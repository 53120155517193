export const THERAPY_TYPE_DOSE = 'dose';
export const THERAPY_TYPE_ASSESSMENT = 'assessment';
/**
 * @description Returns a list of a dosages and assessments for tremfaya
 * @returns {{id: Number, name: String, type: THERAPY_TYPE_DOSE|THERAPY_TYPE_ASSESSMENT, watchField: number, interval: number}[]}
 */
export const tremfyaDosesList = [
  {
    id: 0,
    sequenceNumber: 1,
    name: 'Assessment 1',
    type: THERAPY_TYPE_ASSESSMENT,
    watchField: null,
    displayDescription: true,
    interval: 0
  },
  {
    id: 1,
    sequenceNumber: 1,
    name: 'Dosage 1',
    type: THERAPY_TYPE_DOSE,
    watchField: null,
    interval: 0
  },
  {
    id: 2,
    sequenceNumber: 2,
    name: 'Dosage 2',
    type: THERAPY_TYPE_DOSE,
    watchField: 1,
    interval: 28
  },
  {
    id: 3,
    sequenceNumber: 2,
    name: 'Assessment 2',
    type: THERAPY_TYPE_ASSESSMENT,
    displayDescription: true,
    watchField: 1,
    interval: 91
  },
  {
    id: 4,
    sequenceNumber: 3,
    name: 'Dosage 3',
    type: THERAPY_TYPE_DOSE,
    watchField: 2,
    interval: 56
  },
  {
    id: 5,
    sequenceNumber: 4,
    name: 'Dosage 4',
    type: THERAPY_TYPE_DOSE,
    watchField: 4,
    interval: 56
  },
  {
    id: 6,
    sequenceNumber: 3,
    name: 'Assessment 3',
    type: THERAPY_TYPE_ASSESSMENT,
    displayDescription: true,
    watchField: 3,
    interval: 91
  },
  {
    id: 7,
    sequenceNumber: 5,
    name: 'Dosage 5',
    type: THERAPY_TYPE_DOSE,
    watchField: 5,
    interval: 56
  },
  {
    id: 8,
    sequenceNumber: 6,
    name: 'Dosage 6',
    type: THERAPY_TYPE_DOSE,
    watchField: 7,
    interval: 56
  },
  {
    id: 9,
    sequenceNumber: 7,
    name: 'Dosage 7',
    type: THERAPY_TYPE_DOSE,
    watchField: 8,
    interval: 56
  },
  {
    id: 10,
    sequenceNumber: 4,
    name: 'Assessment 4',
    type: THERAPY_TYPE_ASSESSMENT,
    displayDescription: true,
    watchField: 6,
    interval: 182
  },
  {
    id: 11,
    sequenceNumber: 8,
    name: 'Dosage 8',
    type: THERAPY_TYPE_DOSE,
    watchField: 9,
    interval: 56
  },
  {
    id: 12,
    sequenceNumber: 9,
    name: 'Dosage 9',
    type: THERAPY_TYPE_DOSE,
    watchField: 11,
    interval: 56
  },
  {
    id: 13,
    sequenceNumber: 10,
    name: 'Dosage 10',
    type: THERAPY_TYPE_DOSE,
    watchField: 12,
    interval: 56
  },
  {
    id: 14,
    sequenceNumber: 5,
    name: 'Assessment 5',
    type: THERAPY_TYPE_ASSESSMENT,
    displayDescription: true,
    watchField: 10,
    interval: 182
  },
  {
    id: 15,
    sequenceNumber: 11,
    name: 'Dosage 11',
    type: THERAPY_TYPE_DOSE,
    watchField: 13,
    interval: 56
  },
  {
    id: 16,
    sequenceNumber: 12,
    name: 'Dosage 12',
    type: THERAPY_TYPE_DOSE,
    watchField: 15,
    interval: 56
  },
  {
    id: 17,
    sequenceNumber: 13,
    name: 'Dosage 13',
    type: THERAPY_TYPE_DOSE,
    watchField: 16,
    interval: 56
  },
  {
    id: 18,
    sequenceNumber: 14,
    name: 'Dosage 14',
    type: THERAPY_TYPE_DOSE,
    watchField: 17,
    interval: 56
  },
  {
    id: 19,
    sequenceNumber: 6,
    name: 'Assessment 6',
    type: THERAPY_TYPE_ASSESSMENT,
    displayDescription: true,
    watchField: 14,
    interval: 182
  }
];
