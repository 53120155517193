import React, { useMemo } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';

import { dosageListShape } from '../../utils/therapyUtils';
import {
  THERAPY_TYPE_ASSESSMENT,
  THERAPY_TYPE_DOSE
} from '../../utils/therapySchemas';

const TherapyPdf = ({ doses, name, translate }) => {
  const styles = StyleSheet.create({
    page: {
      display: 'flex',
      justifyContent: 'flex-start',
      backgroundColor: '#fff',
      margin: '10mm 16mm'
    },
    title: {
      marginBottom: '15px'
    },
    drugName: {
      marginBottom: '15px',
      fontSize: '12px'
    },
    itemRow: (type) => ({
      maxWidth: `${210 - 16 - 16}mm`,
      fontSize: '12px',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '7px',
      borderRadius: '5px',
      backgroundColor: type === THERAPY_TYPE_ASSESSMENT ? '#E5E7EB' : undefined
    }),
    itemInlineRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    itemDescription: (description) => ({
      display: description ? 'flex' : 'none',
      paddingTop: description ? '5px' : '0',
      flexWrap: 'wrap',
      alignItems: 'center',
      flexDirection: 'row',
      fontSize: '9px',
      opacity: '0.8'
    })
  });

  const format = translate('date.shortDate');

  const title = useMemo(() => {
    const first = doses.find(Boolean)?.value || undefined;
    return `${moment(first).format(format)}.pdf`;
  }, [doses, format]);

  return (
    <Document title={title}>
      <Page size='A4' style={styles.page} wrap>
        <Text style={styles.title}>{translate('title')}</Text>
        <Text style={styles.drugName}>{translate('drugName', { name })}</Text>
        {doses.map(({ type, value, id, sequenceNumber, description = '' }) => (
          <View key={id} style={styles.itemRow(type)} wrap>
            <View style={styles.itemInlineRow} wrap>
              <Text
                style={{
                  marginLeft: type === THERAPY_TYPE_DOSE ? '10px' : undefined
                }}
              >
                {translate(`therapy.type.${type}Number`, {
                  sequenceNumber: sequenceNumber
                })}
              </Text>
              <Text>{moment(value).format(format)}</Text>
            </View>
            <Text style={styles.itemDescription(description)}>
              {translate(description)}
            </Text>
          </View>
        ))}
      </Page>
    </Document>
  );
};

TherapyPdf.propTypes = {
  doses: dosageListShape,
  translate: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired
};

export default TherapyPdf;
