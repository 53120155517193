import React from 'react';
import PropTypes from 'prop-types';

const Form = ({ children, onSubmit, className }) => (
  <form noValidate className={className} onSubmit={onSubmit}>
    {children}
  </form>
);

Form.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func,
  className: PropTypes.string
};

Form.defaultProps = {
  onSubmit: undefined,
  className: undefined
};

export default Form;
