import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { dosageListShape, fillDosagePath } from '../../utils/therapyUtils';
import { ClipboardListIcon, ClockIcon } from '@heroicons/react/solid';
import DateWrapper from '../form/field/DateWrapper';
import { useWatchAndCalculateFieldValue } from '../../hooks/TherapyHooks';
import { useModal } from '../layout/Modal';
import {
  THERAPY_TYPE_ASSESSMENT,
  THERAPY_TYPE_DOSE
} from '../../utils/therapySchemas';

const therapyIcons = {
  [THERAPY_TYPE_DOSE]: (
    <ClockIcon className={'h-5 w-5 md:w-7 md:h-7 text-white'} />
  ),
  [THERAPY_TYPE_ASSESSMENT]: (
    <ClipboardListIcon className={'h-5 w-5 md:w-7 md:h-7 text-white'} />
  )
};

const therapyColor = {
  [THERAPY_TYPE_DOSE]: 'bg-orange-500',
  [THERAPY_TYPE_ASSESSMENT]: 'bg-blue-600'
};

const TherapyFormItem = ({
  index,
  field,
  calculationList,
  displayConnection
}) => {
  const { t: translate } = useTranslation();
  const [isOpen, toggleModal] = useModal();
  const control = useWatchAndCalculateFieldValue(field, calculationList);

  return (
    <li className='relative pb-6 md:pb-8'>
      {displayConnection && (
        <span
          className='absolute left-4 top-4 -ml-px w-0.5 h-full bg-gray-300 md:left-5'
          aria-hidden='true'
        />
      )}
      <div
        onClick={toggleModal}
        className='relative flex pr-2 bg-white bg-opacity-50 hover:bg-opacity-80 rounded-md cursor-pointer space-x-3 transition duration-100 ease-in drop-shadow-md filter'
      >
        <div>
          <span
            className={classNames(
              therapyColor[field?.type],
              'flex items-center justify-center w-8 h-8 rounded-full ring-4 ring-white md:w-10 md:h-10 md:ring-8'
            )}
          >
            {therapyIcons[field?.type]}
          </span>
        </div>
        <div className='flex flex-1 items-center justify-between min-w-0 space-x-4'>
          <div>
            <p className='text-primary-blue-800 text-sm md:pl-1.5 md:text-lg'>
              {translate(`therapy.type.${field?.type}Number`, {
                sequenceNumber: field?.sequenceNumber
              })}
            </p>
          </div>
          <div className='text-right text-primary-blue-800 whitespace-nowrap'>
            <DateWrapper
              control={control}
              name={fillDosagePath(index)}
              description={field?.description}
              isOpen={isOpen}
              toggleModal={toggleModal}
            />
          </div>
        </div>
      </div>
    </li>
  );
};

TherapyFormItem.propTypes = {
  index: PropTypes.number.isRequired,
  field: PropTypes.shape().isRequired,
  calculationList: dosageListShape,
  displayConnection: PropTypes.bool.isRequired
};

export default TherapyFormItem;
