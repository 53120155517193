import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DatePickerModal from './DatePickerModal';

const DateWrapper = ({ name, description, control, isOpen, toggleModal }) => {
  const { t: translate } = useTranslation();

  const {
    field: { value, onChange }
  } = useController({
    name,
    control
  });

  const format = translate('date.shortDate');

  const formattedValue = useMemo(() => {
    return moment(value).format(format);
  }, [format, value]);

  const onDateChange = (val) => {
    onChange(moment(val).toISOString());
    toggleModal();
  };

  return (
    <React.Fragment>
      <span className={'md:text-lg'}>{formattedValue}</span>

      {isOpen && (
        <DatePickerModal
          isOpen={isOpen}
          toggleModal={toggleModal}
          value={value}
          description={description}
          onChange={onDateChange}
        />
      )}
    </React.Fragment>
  );
};

DateWrapper.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  control: PropTypes.shape().isRequired
};

export default React.memo(DateWrapper);
