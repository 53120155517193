import { useFormContext } from 'react-hook-form';
import { calculateNewInterval, fillDosagePath } from '../utils/therapyUtils';
import { useCallback, useEffect } from 'react';
import moment from 'moment';

export const useWatchAndCalculateFieldValue = (field, calculationList) => {
  const { control, watch, setValue } = useFormContext();
  const watchedFieldValue = watch(fillDosagePath(field?.watchField), null);

  const updateBasedOnWatchedField = useCallback(() => {
    const watchedFieldObject = calculationList.find(
      (dose) => dose?.id === field?.id
    );

    const interval = watchedFieldObject?.interval;
    if (interval) {
      const newFieldValue = calculateNewInterval(watchedFieldValue, interval);

      setValue(fillDosagePath(field?.id), newFieldValue);
    }
  }, [calculationList, field?.id, setValue, watchedFieldValue]);

  useEffect(() => {
    if (moment(watchedFieldValue).isValid()) {
      updateBasedOnWatchedField();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedFieldValue]);

  return control;
};
