import React, { useCallback } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { pdf } from '@react-pdf/renderer';

import { useGenerateInitialDates } from '../../utils/therapyUtils';

import Button from '../Button';
import Form from '../form/Form';
import TherapyFormItems from './TherapyFormItems';
import TherapyPdf from './TherapyPdf';
import { tremfyaDosesList } from '../../utils/therapySchemas';

const useHandleDownload = () => {
  const { t: translate } = useTranslation();

  return useCallback(
    ({ doses = [], name = '' }) => {
      pdf(<TherapyPdf doses={doses} name={name} translate={translate} />)
        .toBlob()
        .then((blob) => {
          const file = new Blob([blob], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);

          window.open(fileURL);

          /*
          const userAgent = navigator.userAgent.toLowerCase();
          const isAndroid = userAgent.indexOf('android') > -1;
          if (isAndroid) {
            window.open(fileURL);
          } else {
            const iframe = document.createElement('iframe');
            document.body.appendChild(iframe);

            iframe.style.display = 'none';
            iframe.src = fileURL;
            iframe.onload = () => {
              setTimeout(() => {
                iframe.focus();
                iframe.contentWindow.print();
              }, 1);
            };
          }*/
        });
    },
    [translate]
  );
};

const useTherapyInitialValues = () => {
  const { initialDoses, schema } = useGenerateInitialDates(tremfyaDosesList);

  return {
    dosageList: schema,
    initialValues: {
      name: 'Tremfya 100mg SOL INJ.',
      doses: initialDoses
    }
  };
};

const TherapyCalculatorForm = () => {
  const { t: translate } = useTranslation();
  const { dosageList, initialValues } = useTherapyInitialValues();
  const onFormSubmit = useHandleDownload();

  const methods = useForm({
    defaultValues: initialValues
  });

  const { handleSubmit, control } = methods;

  const { fields } = useFieldArray({
    control,
    name: 'doses'
  });

  return (
    <FormProvider {...methods}>
      <Form
        onSubmit={handleSubmit(onFormSubmit)}
        className={'flex flex-col p-5 md:p-0'}
      >
        <div className={'pt-3 pb-5'}>
          <div className={'flex flex-col text-sm md:text:md pb-4 px-1 md:px-0'}>
            <span className={'opacity-90 text-primary-blue-800'}>
              {translate('drug')}
            </span>
            <span className={'text-primary-blue-800 font-bold'}>
              Tremfya 100mg SOL INJ.
            </span>
          </div>
          <TherapyFormItems fields={fields} calculationList={dosageList} />
        </div>
        <iframe title={'Print area'} id={'print-frame'} className={'hidden'} />
        <Button className={'mt-6'} type={'submit'}>
          {translate('generatePdf')}
        </Button>
      </Form>
    </FormProvider>
  );
};

export default TherapyCalculatorForm;
