import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import favicon from '../assets/favicon.ico';

const PageMeta = () => {
  const { t: translate } = useTranslation();
  return (
    <Helmet defer={false}>
      <link rel='icon' href={favicon} />
      <title>{translate('pageTitle')}</title>
    </Helmet>
  );
};

PageMeta.propTypes = {};

export default PageMeta;
