import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DayPickerSingleDateController } from 'react-dates';

import Button from '../../Button';
import Modal from '../../layout/Modal';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './DayPicker.css';

const DatePickerModal = ({
  isOpen,
  toggleModal,
  onChange,
  value,
  description
}) => {
  const [datePickerValue, setDatePickerValue] = useState(value);
  const {
    t: translate,
    i18n: { exists }
  } = useTranslation();

  const firstDayOfWeek = useMemo(() => {
    const firstDay = translate('date.firstDayOfWeek');

    return parseInt(firstDay);
  }, [translate]);

  const onDateChange = (val) => {
    setDatePickerValue(val);
  };

  const onConfirmDate = () => {
    onChange(datePickerValue);
  };

  return (
    <Modal
      isOpen={isOpen}
      title={translate('date.select')}
      toggleModal={toggleModal}
    >
      {description && exists(description) && (
        <p
          className={
            'opacity-90 text-primary-blue-800 text-center text-sm px-3'
          }
        >
          {translate(description)}
        </p>
      )}
      <div className={'flex flex-col items-center'}>
        <DayPickerSingleDateController
          firstDayOfWeek={firstDayOfWeek}
          date={moment(datePickerValue)}
          onDateChange={onDateChange}
          numberOfMonths={1}
          focused
          hideKeyboardShortcutsPanel
        />
        <Button className={'mt-1 w-full'} onClick={onConfirmDate}>
          {translate('confirm')}
        </Button>
      </div>
    </Modal>
  );
};

DatePickerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default DatePickerModal;
