import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Modal from '../layout/Modal';

const TherapyInstructionsModal = ({ isOpen, toggleModal }) => {
  const { t: translate } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      title={translate('date.select')}
      toggleModal={toggleModal}
    >
      <div className={'flex flex-col items-center mt-3'}>
        <p className={'text-gray-500 text-sm text-justify'}>
          {translate('instructions')}
        </p>
      </div>
    </Modal>
  );
};

TherapyInstructionsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired
};

export default TherapyInstructionsModal;
