import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { dosageListShape } from '../../utils/therapyUtils';

import TherapyFormItem from './TherapyFormItem';

const TherapyFormItems = ({ calculationList }) => {
  const { control } = useFormContext();

  const { fields } = useFieldArray({
    control,
    name: 'doses'
  });

  return (
    <div className='flow-root'>
      <ul role='list' className='-mb-8'>
        {fields.map((field, index) => (
          <TherapyFormItem
            index={index}
            key={field.id}
            field={field}
            displayConnection={index !== fields.length - 1}
            calculationList={calculationList}
          />
        ))}
      </ul>
    </div>
  );
};

TherapyFormItems.propTypes = {
  calculationList: dosageListShape
};

export default TherapyFormItems;
