import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Button = ({ type, className, children, onClick }) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className={classNames(
        className,
        'px-4 py-3 text-white font-semibold bg-primary-blue-800 hover:bg-opacity-90 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-blue-800 focus:ring-opacity-70 focus:ring-offset-1'
      )}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func
};

Button.defaultProps = {
  className: 'undefined',
  type: 'button',
  onClick: undefined
};

export default Button;
