import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const CommonLayout = ({ className, children }) => (
  <div className={classNames('w-full md:max-w-screen-sm', className)}>
    {children}
  </div>
);

CommonLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

CommonLayout.defaultProps = {
  className: undefined
};

export default CommonLayout;
