import moment from 'moment';
import PropTypes from 'prop-types';
import { THERAPY_TYPE_ASSESSMENT, THERAPY_TYPE_DOSE } from './therapySchemas';
import { useMemo } from 'react';

export const useGenerateInitialDates = (schema) => {
  const initialDoses = useMemo(() => {
    const dates = [];

    schema.forEach(
      ({
        id,
        sequenceNumber,
        watchField,
        type,
        interval,
        displayDescription = false
      }) => {
        let value;
        if (!Number.isInteger(watchField)) {
          value = moment().toISOString();
        } else {
          const { value: watchFieldValue } = dates.find(
            (dose) => dose?.id === watchField
          );
          value = calculateNewInterval(watchFieldValue, interval);
        }

        const description = displayDescription
          ? `therapy.description.${type}-${sequenceNumber}`
          : '';

        dates.push({
          id,
          type,
          value,
          watchField,
          sequenceNumber,
          description
        });
      }
    );

    return dates;
  }, [schema]);

  return { initialDoses, schema };
};

export const calculateNewInterval = (date, interval) => {
  if (moment(date).isValid()) {
    return moment(date).add(interval, 'days').toISOString();
  }

  throw new Error('Invalid date');
};

export const fillDosagePath = (index) => `doses.${index}.value`;

export const dosageListShape = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.oneOf([THERAPY_TYPE_ASSESSMENT, THERAPY_TYPE_DOSE]),
    watchField: PropTypes.number,
    interval: PropTypes.number
  })
);
