import React, { Fragment, useCallback, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { XIcon } from '@heroicons/react/outline';

export const SIZE_SM = 'sm';
export const SIZE_MD = 'md';
export const SIZE_LG = 'lg';

export const useModal = () => {
  const initialFocusRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return [isOpen, toggleModal, initialFocusRef];
};

const Modal = ({ title, isOpen, size, toggleModal, focusRef, children }) => {
  const modalSize = {
    [SIZE_SM]: 'max-w-sm',
    [SIZE_MD]: 'max-w-md',
    [SIZE_LG]: 'max-w-lg'
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        initialFocus={focusRef}
        as='div'
        className='fixed z-10 inset-0 overflow-y-auto'
        onClose={toggleModal}
      >
        <div className='flex items-center justify-center px-4 min-h-screen text-center'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-100'
            enterFrom='opacity-0'
            enterTo='opacity-75'
            leave='ease-in duration-100'
            leaveFrom='opacity-75'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-600 bg-opacity-25 backdrop-blur-sm backdrop-filter' />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter='ease-out duration-100'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-75 scale-100'
            leave='ease-in duration-100'
            leaveFrom='opacity-75 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <div
              className={classNames(
                modalSize[size],
                'inline-block align-middle my-8 p-6 w-full text-left bg-white shadow-xl overflow-hidden overflow-visible transform transition-all'
              )}
            >
              <Dialog.Title
                as='h3'
                className='relative text-gray-900 text-lg font-medium leading-6'
              >
                {title}
                <button
                  tabIndex='-1'
                  type='button'
                  className='absolute -right-2 inline-flex items-center justify-center p-0.5 text-gray-400 hover:text-gray-500 hover:bg-gray-100 rounded-md'
                  aria-expanded={isOpen}
                  aria-label='Close modal'
                  onClick={toggleModal}
                >
                  <XIcon className='w-6 h-6' />
                </button>
              </Dialog.Title>
              <div className='mt-3'>{children}</div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  size: PropTypes.oneOf([SIZE_SM, SIZE_MD, SIZE_LG]),
  focusRef: PropTypes.shape(),
  children: PropTypes.node
};

Modal.defaultProps = {
  size: SIZE_SM,
  focusRef: undefined,
  children: undefined
};

export default Modal;
